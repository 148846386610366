import { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import Image from 'next/image';
import { truncate } from 'shared/utils/stringFns';

const showElement = keyframes`
{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

const Card = styled.div`
  display: grid;
  grid-gap: 2.4rem;

  @media ${({ theme }) => theme.DEVICE.XL} {
    grid-template-columns: 3fr 5fr;
    height: 88rem;
  }
`;

const MobileNavigationWrapper = styled.ul`
  display: flex;
  padding: 1.2rem 2.4rem;
  list-style-type: none;

  @media ${({ theme }) => theme.DEVICE.XL} {
    display: none;
  }
`;

const NavigationWrapper = styled.ul`
  display: none;

  @media ${({ theme }) => theme.DEVICE.XL} {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const NavigationItem = styled.li`
  @media ${({ theme }) => theme.DEVICE.XL} {
    display: grid;
    grid-template-columns: 8rem 1fr;
    margin-bottom: 5.2rem;
    position: relative;

    :after {
      content: '';
      bottom: -3.9rem;
      left: 2.8rem;
      position: absolute;
      width: 0.15rem;
      background: #f1f1f1;
      height: calc(100% - 3.2rem);
    }
    :last-child {
      :after {
        content: none;
      }
    }

    :hover {
      cursor: pointer;
    }
  }
`;

const Heading = styled.h2`
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.1rem;
  text-align: left;
  color: ${({ theme }) => theme.COLORS.V2.PRIMARY};
  margin-bottom: 1rem;
  width: auto;

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 2.8rem;
  }
`;

const Description = styled.div`
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2.1rem;
  text-align: left;
  color: #637381;

  @media ${({ theme }) => theme.DEVICE.XL} {
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.8rem;
  }
`;

const DotWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.2rem;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.DEVICE.XL} {
    display: none;
  }
`;

const Dot = styled.div<Pick<ItemProps, 'isActive'>>`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 2.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.8s ease-in;
  border: 2px solid ${({ theme }) => theme.COLORS.V2.SECONDARY};
  color: ${({ theme, isActive }) => (isActive ? theme.COLORS.V2.SECONDARY : theme.COLORS.WHITE)};
  background: ${({ theme, isActive }) => (isActive ? theme.COLORS.WHITE : theme.COLORS.V2.SECONDARY)};

  :hover {
    cursor: pointer;
  }
`;

interface SingleImageWrapperProps {
  isActive: boolean;
  width: number;
  height: number;
}

const SingleImageWrapper = styled.div<SingleImageWrapperProps>`
  @media (max-width: ${({ theme }) => theme.BREAKPOINT.MD}) {
    max-width: 90%;
  }
  align-items: center;
  justify-content: center;
  display: flex;
  animation: ${showElement} 0.9s ease-in-out;
  ${({ isActive }) =>
    !isActive &&
    css`
      display: none;
    `}
  height: 100%;
  width: 100%;
  justify-content: center;
  max-height: ${({ height }) => `${height}px`};
  max-width: ${({ width }) => `${width}px`};
  position: relative;

  img {
    max-height: ${({ height }) => `${height}px`} !important;
    max-width: ${({ width }) => `${width}px`} !important;
  }
`;

const ImageWrapper = styled.div<Pick<ItemProps, 'inPhone' | 'fitValue'>>`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ inPhone, fitValue }) =>
    inPhone &&
    css`
      img {
        border-radius: 5rem;
      }

      ${SingleImageWrapper} {
        transform: scale(${fitValue});
      }
    `};
`;

interface PhoneProps {
  height: number;
}

const Phone = styled.div<PhoneProps>`
  position: absolute;
  max-height: 67.4rem;
  height: 100%;
  width: 100%;
  max-width: 33.4rem;
  min-height: 50rem !important;
`;

export const BiggerDot = styled.div`
  display: none;
  z-index: -1;

  @media ${({ theme }) => theme.DEVICE.XL} {
    background: #ffad33;
    border-radius: 50%;
    bottom: 11rem;
    display: block;
    height: 58rem;
    position: absolute;
    right: 15%;
    width: 58rem;
  }
`;

export const SmallDot = styled.span<Pick<ItemProps, 'trans'>>`
  display: none;

  @media ${({ theme }) => theme.DEVICE.XL} {
    background: #fc8000;
    border-radius: 50%;
    bottom: 6rem;
    display: block;
    height: 30.4rem;
    position: absolute;
    right: 4rem;
    transition: all 1s ease;
    width: 30.4rem;
    z-index: -1;
    ${({ trans }) =>
      trans === 1 &&
      css`
        transform: translate(0, -44rem);
      `};
    ${({ trans }) =>
      trans === 2 &&
      css`
        transform: translate(-48rem, -2rem);
      `};
    ${({ trans }) =>
      trans === 3 &&
      css`
        transform: translate(-48rem, -40rem);
      `};
  }
`;

type DotSectionProps = {
  navigationItems: {
    id: number;
    header: string;
    description: string;
  }[];
  images: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    src: any;
    height: number;
    width: number;
    noDots?: boolean;
  }[];
  inPhone?: boolean;
  fitValue?: number;
  noDots?: boolean;
};

type ItemProps = {
  trans: number;
  isActive: boolean;
  inPhone?: boolean;
  fitValue: number;
};

function DotSection({
  navigationItems,
  images,
  inPhone = false,
  fitValue = 0.93,
  noDots = false,
}: DotSectionProps): JSX.Element {
  const [selected, setSelected] = useState(0);
  return (
    <Card>
      <DotWrapper>
        {navigationItems.map(({ id }) => (
          <Dot onClick={() => setSelected(id - 1)} isActive={selected === id - 1} key={id}>{`0${id}`}</Dot>
        ))}
      </DotWrapper>
      <NavigationWrapper>
        {navigationItems.map(({ id, header, description }) => (
          <NavigationItem key={id} onClick={() => setSelected(id - 1)}>
            <Dot key={id} isActive={selected === id - 1}>{`0${id}`}</Dot>
            <div>
              <Heading>{header}</Heading>
              <Description>{selected === id - 1 ? description : truncate(description, 114)}</Description>
            </div>
          </NavigationItem>
        ))}
      </NavigationWrapper>
      <MobileNavigationWrapper>
        <NavigationItem>
          <Heading>{navigationItems[selected]?.header}</Heading>
          <Description>{navigationItems[selected]?.description}</Description>
        </NavigationItem>
      </MobileNavigationWrapper>
      <ImageWrapper fitValue={fitValue} inPhone={inPhone}>
        {(noDots && selected === 1) || images[selected].noDots ? null : (
          <>
            <SmallDot trans={selected} />
            <BiggerDot />
          </>
        )}
        {inPhone ? (
          <Phone height={images[0].height}>
            <Image
              src="/img/collecting-reviews/phone-default.png"
              alt=""
              width={334}
              height={674}
              quality={100}
              lazyBoundary="200px"
              layout="responsive"
            />
          </Phone>
        ) : null}
        {images.map((item, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <SingleImageWrapper key={key.toString()} isActive={key === selected} height={item.height} width={item.width}>
            <Image
              src={item.src}
              alt=""
              width={item.width}
              height={item.height}
              quality={100}
              lazyBoundary="800px"
              layout="responsive"
            />
          </SingleImageWrapper>
        ))}
      </ImageWrapper>
    </Card>
  );
}

export default DotSection;
