import styled, { css, keyframes } from 'styled-components';

type HeaderProps = {
  isColored?: boolean;
};

export const Section = styled.section`
  max-width: 160rem;
  margin: 0 auto;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  padding: 0;

  @media ${({ theme }) => theme.DEVICE.XL} {
    padding: 3.2rem;
  }
`;

const headerStyles = css<HeaderProps>`
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 4rem;
  font-weight: 600;
  line-height: 4.5rem;
  text-align: center;

  @media ${({ theme }) => theme.DEVICE.XL} {
    font-size: 7.8rem;
    line-height: 8.3rem;
  }
`;

export const StyledH1 = styled.h1<HeaderProps>`
  ${headerStyles};
  color: ${({ theme, isColored }) => (isColored ? theme.COLORS.V2.SECONDARY : theme.COLORS.V2.PRIMARY)};
`;

export const StyledH2 = styled.h2<HeaderProps>`
  color: ${({ theme, isColored }) => (isColored ? theme.COLORS.V2.SECONDARY : theme.COLORS.V2.PRIMARY)};
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.9rem;
  text-align: center;
  margin-top: 0.8rem;

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 5.3rem;
    font-weight: 700;
    line-height: 6rem;
  }
`;

export const StyledH3 = styled.h3<HeaderProps>`
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 3.2rem;
  text-align: center;
  color: ${({ theme, isColored }) => (isColored ? theme.COLORS.V2.SECONDARY : theme.COLORS.V2.PRIMARY)};

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3.2rem;
  }
`;

export const Header = styled.header`
  text-align: center;
  padding: 1.6rem 0;

  @media ${({ theme }) => theme.DEVICE.XL} {
    padding: 2.4rem 3.2rem;
  }
`;

export const moveElement = keyframes`
{
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0em, -3em);
  }
  50% {
    transform: translate(-2em, -3em);
  }
  75% {
    transform: translate(-3em, 0em);
  }
  100% {
    transform: translate(0, 0);
  }
}
`;

export const dotStyles = css`
  background: #fc8000;
  font-size: 0.4rem;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  display: none;

  @media ${({ theme }) => theme.DEVICE.SM} {
    font-size: 0.5em;
  }

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 0.8em;
  }

  @media ${({ theme }) => theme.DEVICE.XL} {
    font-size: 1.1em;
    display: block;
  }
`;

export const AnimatedDotSmall = styled.div`
  right: 10%;
  ${dotStyles};
  width: 30.4em;
  height: 30.4em;
  background: #fc8000;
  animation: ${moveElement} 12s ease infinite;
`;

export const AnimatedDotBig = styled.div`
  ${dotStyles};
  animation: ${moveElement} 18s ease infinite;
  right: 25%;
  width: 57.7em;
  height: 57.7em;
  background: #ffad33;
`;
