import styled from 'styled-components';
import { StyledH2, StyledH3 } from 'shared/components/DotSection/Styled';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 12rem auto 4rem auto;
  text-align: center;

  @media ${({ theme }) => theme.DEVICE.XL} {
    width: 73%;
  }
`;

export const Description = styled.p`
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
  padding: 2.4rem;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  color: ${({ theme }) => theme.COLORS.V2.PRIMARY};

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 2rem;
    line-height: 2.8rem;
    padding: 3.2rem;
  }
`;

type HeaderSectionProps = {
  header: string;
  description: string;
  topHeader?: string;
};

function HeaderSection({ header, topHeader, description }: HeaderSectionProps): JSX.Element {
  return (
    <HeaderWrapper>
      {topHeader ? <StyledH3 isColored>{topHeader}</StyledH3> : null}
      <StyledH2>{header}</StyledH2>
      <Description>{description}</Description>
    </HeaderWrapper>
  );
}

export default HeaderSection;
