import useTranslation from 'shared/hooks/useTranslation';
import DotSection from 'shared/components/DotSection/DotSection';
import { Section } from 'shared/components/DotSection/Styled';

import HeaderSection from './HeaderSection';

function OrganicTaffic(): JSX.Element {
  const { t, lang } = useTranslation('homePage');

  const NAVIGATION_REVIEWS = [
    {
      id: 1,
      header: t('dotsSection.plan'),
      description: t('dotsSection.planDescription'),
    },
    {
      id: 2,
      header: t('dotsSection.start'),
      description: t('dotsSection.startDescription'),
    },
    {
      id: 3,
      header: t('dotsSection.progress'),
      description: t('dotsSection.progressDescription'),
    },
    {
      id: 4,
      header: t('dotsSection.growth'),
      description: t('dotsSection.growthDescription'),
    },
  ];

  const CONTENT_IMAGES = [
    {
      src: `/img/home/v2/dots/${lang === 'pl' ? 'pl' : 'en'}/widgets.png`,
      width: 708,
      height: 685,
    },
    {
      src: `/img/home/v2/dots/${lang === 'pl' ? 'pl' : 'en'}/hotels-testimonial.png`,
      width: 660,
      height: 577,
    },
    {
      src: `/img/home/v2/dots/${lang === 'pl' ? 'pl' : 'en'}/search-results.png`,
      width: 900,
      height: 663,
    },
    {
      src: `/img/home/v2/dots/${lang === 'pl' ? 'pl' : 'en'}/reviews-chunks.png`,
      width: lang === 'pl' ? 716 : 726,
      height: lang === 'pl' ? 758 : 703,
    },
  ];

  return (
    <Section>
      <HeaderSection header={t('dotsSection.success' as const)} description={t('dotsSection.cooperation' as const)} />
      <DotSection navigationItems={NAVIGATION_REVIEWS} images={CONTENT_IMAGES} noDots />
    </Section>
  );
}

export default OrganicTaffic;
